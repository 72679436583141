import auth0, { Auth0UserProfile, AuthorizeOptions } from 'auth0-js';

import { AuthResult, UserProfile } from '.';
import { env } from '../utils/env';

export interface Auth {
    accessToken: string;
    idToken: string;
    expiresAt: string;
    userProfile: UserProfile;
}

const ACCESS_DENIED_FOR_COMPANY = `ACCESS_DENIED_FOR_COMPANY`;

const AUTH_REALM = 'Username-Password-Authentication';
const AUTH_DOMAIN = env.AUTH_DOMAIN as string;
const AUTH_CLIENT_ID = env.AUTH_CLIENT_ID as string;
const AUTH_AUDIENCE = env.AUTH_AUDIENCE as string;
const AUTH_RESPONSE_TYPE = 'token id_token';
const AUTH_SCOPES = [
    'openid',
    'profile',
    'email',
    'read:viewer',
    'read:companies',
    'read:companies:admin',
    'read:licenses',
    'read:licenses:admin',
    'read:sdks',
    'read:features',
    'create:license:admin',
    'create:license_trial',
    'update:license_trial',
    'update:license:admin',
    'update:license_domains',
    'update:license_domains:admin',
    'update:license_features:admin',
    'update:license_expiration:admin',
    'create:license_build',
    'create:license_build:admin',
    'read:license:analytics',
];

const authConfig = {
    realm: AUTH_REALM,
    domain: AUTH_DOMAIN,
    clientID: AUTH_CLIENT_ID,
    audience: AUTH_AUDIENCE,
    responseType: AUTH_RESPONSE_TYPE,
    scopes: AUTH_SCOPES.join(' '),
};

class AuthService {
    public seenSupportPlans: boolean = false;
    private auth?: Auth;
    private webAuth: auth0.WebAuth;

    constructor() {
        const accessToken = localStorage.getItem('access_token');
        const idToken = localStorage.getItem('id_token');
        const expiresAt = localStorage.getItem('expires_at');
        const userProfile = localStorage.getItem('user_profile');

        if (accessToken && idToken && expiresAt && userProfile) {
            this.auth = {
                accessToken,
                idToken,
                expiresAt,
                userProfile: this.deserializeUserProfile(userProfile),
            };
        }

        this.webAuth = new auth0.WebAuth({
            domain: authConfig.domain,
            clientID: authConfig.clientID,
        });
    }

    public isAuthenticated = () => {
        return !this.tokenIsExpired();
    };

    public isAnalytics = () => {
        const profile = this.getUserProfile();

        return profile && profile.isAnalytics;
    };

    public hasSportsbookAccountButNotAcceptedTcYet = () => {
        const profile = this.getUserProfile();
        return typeof profile !== 'undefined' && profile.needsToAcceptSportsbookTC === true;
    };

    public isAdmin = (): boolean => {
        const profile = this.getUserProfile();
        return profile && profile.isAdmin ? true : false;
    };

    public isSuperAdmin = () => {
        const profile = this.getUserProfile();
        return profile && profile.isSuperAdmin;
    };

    public getUsername = () => {
        const profile = this.getUserProfile();
        return profile && profile.username;
    };

    public getCompany = () => {
        const profile = this.getUserProfile();
        return profile && profile.company;
    };

    public isResellerAccount = () => {
        const profile = this.getUserProfile();
        return profile && profile.isResellerAccount;
    };

    public isAdminReadOnly = () => {
        const profile = this.getUserProfile();
        return profile && profile.isAdminReadOnly;
    };

    public isCustomerReadOnly = () => {
        const profile = this.getUserProfile();
        return profile && profile.isCustomerReadOnly;
    };

    public isEnterpriseUser = () => {
        const profile = this.getUserProfile();
        return profile && profile.isEnterpriseUser;
    };

    public isAmLead = () => {
        const profile = this.getUserProfile();
        return profile && profile.isAmLead;
    };

    public isPaygUser = () => {
        const profile = this.getUserProfile();
        return profile && profile.isPaygUser;
    };

    public getRoleName = () => {
        return this.isAdminReadOnly()
            ? 'THEO Read-only Admin'
            : this.isAdmin()
            ? 'THEO Admin'
            : this.isResellerAccount()
            ? 'Reseller'
            : this.isEnterpriseUser()
            ? 'Enterprise user'
            : this.isPaygUser()
            ? 'Pay-as-you-go user'
            : 'Portal customer';
    };

    public getPicture = () => {
        const profile = this.getUserProfile();
        return profile && profile.picture;
    };

    public canWrite = () => {
        const profile = this.getUserProfile();
        return (
            profile &&
            ((!profile.isAdminReadOnly && profile.isAdmin) || (!profile.isAdmin && !profile.isCustomerReadOnly))
        );
    };

    public getUserProfile = () => {
        return this.auth && this.auth.userProfile;
    };

    public getAccessToken = () => {
        return this.auth && this.auth.accessToken;
    };

    public tokenIsExpired = () => {
        if (!this.auth) {
            return true;
        }
        const localExpire = parseInt(
            localStorage.getItem('expires_at') ? localStorage.getItem('expires_at')! : '0',
            10,
        );
        const expiresAt = JSON.parse(this.auth.expiresAt);
        return new Date().getTime() >= expiresAt || new Date().getTime() >= localExpire;
    };

    public isActivated = () => {
        const profile = this.getUserProfile();

        return profile && profile.isActivated;
    };

    public isFirstLogin = () => {
        const profile = this.getUserProfile();

        return profile && profile.isFirstLogin;
    };

    public setSeenSupportPlans = () => {
        this.seenSupportPlans = true;
    };

    public authenticateWithGoogle = () => {
        const BASE_URL = env.BASE_URL as string;
        const options: AuthorizeOptions = {
            connection: 'google-oauth2',
            responseType: 'token',
            redirectUri: `${BASE_URL}/login/callback`,
            audience: authConfig.audience,
            scope: authConfig.scopes,
        };
        this.webAuth.authorize(options);
    };

    public authenticateWithGithub = () => {
        const BASE_URL = env.BASE_URL as string;
        const options: AuthorizeOptions = {
            connection: 'github',
            responseType: 'token',
            redirectUri: `${BASE_URL}/login/callback`,
            audience: authConfig.audience,
            scope: authConfig.scopes,
        };
        this.webAuth.authorize(options);
    };

    public startSession = async (authResult: AuthResult, profile: Auth0UserProfile) => {
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

        const roles = profile['https://www.theoplayer.com/roles'];

        const userMetadata = profile['https://www.theoplayer.com/user_metadata'];
        const appMetadata = profile['https://www.theoplayer.com/app_metadata'];

        const customProfile = {
            ...profile,
            ...userMetadata,
            ...appMetadata,
            roles,
        };

        const userProfile = JSON.stringify(customProfile);

        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('user_profile', JSON.stringify(customProfile));

        this.auth = {
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            expiresAt,
            userProfile: this.deserializeUserProfile(userProfile),
        };

        const id = this.auth.userProfile.id;
        const graphqlEndpoint = env.GRAPHQL_ENDPOINT as string;

        const result = await fetch(graphqlEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + authResult.accessToken,
            },
            body: JSON.stringify({
                query: 'mutation loginMutation($input: LoginInput!) { login(input: $input) }',
                variables: { input: { auth0Id: id } },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                const { errors } = res;
                if (errors && errors.length > 0) {
                    const hasAccessDeniedError = errors.find(
                        (error: any) => error.message === ACCESS_DENIED_FOR_COMPANY,
                    );
                    if (hasAccessDeniedError) {
                        this.destroySession();
                        return false;
                    }
                }

                return true;
            });

        return result;
    };

    public destroySession = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_profile');

        this.auth = undefined;
    };

    public login = (email: string, password: string) =>
        new Promise<any>((resolve, reject) => {
            this.webAuth.client.login(
                {
                    realm: 'Username-Password-Authentication',
                    username: email,
                    password,
                    audience: authConfig.audience,
                    scope: authConfig.scopes,
                },
                (error, authResult) => (error ? reject(error) : resolve(authResult)),
            );
        });

    public fetchUserProfile = (accessToken: string) =>
        new Promise<any>((resolve, reject) => {
            this.webAuth.client.userInfo(accessToken, (error, profile) => (error ? reject(error) : resolve(profile)));
        });

    public resetPassword = (email: string) =>
        new Promise<any>((resolve, reject) => {
            this.webAuth.changePassword({ connection: 'Username-Password-Authentication', email }, (error, result) =>
                error ? reject(error) : resolve(result),
            );
        });

    public changePassword = (email: string, password: string) =>
        new Promise<any>((resolve, reject) => {
            this.webAuth.changePassword(
                {
                    connection: 'Username-Password-Authentication',
                    email,
                    password,
                },
                (error, result) => (error ? reject(error) : resolve(result)),
            );
        });

    private deserializeUserProfile = (userProfile: string) => {
        const auth0UserProfile: Auth0UserProfile = JSON.parse(userProfile);
        const roles = ((auth0UserProfile as any).roles || []) as string[];
        const company = ((auth0UserProfile as any).company || '') as string;
        const isAdmin = roles.indexOf('admin') !== -1;
        const isSuperAdmin = roles.indexOf('superadmin') !== -1;
        const isAdminReadOnly = roles.indexOf('admin_read_only') !== -1;
        const isCustomerReadOnly = roles.indexOf('customer_read_only') !== -1;
        const isResellerAccount = roles.indexOf('reseller') !== -1;
        const isEnterpriseUser = roles.indexOf('enterprise') !== -1;
        const isAmLead = roles.indexOf('am_lead') !== -1;
        const isPaygUser = roles.indexOf('pay-as-you-go') !== -1 || roles.indexOf('pay_as_you_go') !== -1;
        const isAnalytics = roles.indexOf('analytics') !== -1;
        const userMetaData = (auth0UserProfile['https://www.theoplayer.com/user_metadata'] || {}) as any;

        const isActivated = !!userMetaData.activated;
        const needsToAcceptSportsbookTC = userMetaData.sportsbook?.acceptedTc === false;

        const accountType = userMetaData.account_type || 'TRIAL';
        const isFirstLogin = userMetaData.loginsCount === 1 || !userMetaData.loginsCount;
        return {
            id: auth0UserProfile.sub,
            username: auth0UserProfile.email ? auth0UserProfile.email : auth0UserProfile.name,
            picture: auth0UserProfile.picture,
            firstName: userMetaData.firstName
                ? userMetaData.firstName
                : auth0UserProfile.given_name
                ? auth0UserProfile.given_name
                : '',
            lastName: userMetaData.lastName
                ? userMetaData.lastName
                : auth0UserProfile.family_name
                ? auth0UserProfile.family_name
                : '',
            phone: userMetaData.phone ? userMetaData.phone : '',
            company,
            isAdmin,
            isSuperAdmin,
            isAdminReadOnly,
            isCustomerReadOnly,
            isResellerAccount,
            isEnterpriseUser,
            isPaygUser,
            isAnalytics,
            isActivated,
            isFirstLogin,
            isAmLead,
            accountType,
            needsToAcceptSportsbookTC,
        };
    };
}

// Singleton because we only want one of these for the entire app.
export default new AuthService();
