import React from 'react';

import { Grid, Hidden, makeStyles } from '@material-ui/core';
import theme from '../../styles/createMuiTheme';
import styled from 'styled-components';
import sportsbookBackground from '../../img/sportsbook-background.jpg';

const Title = styled.h1`
    font-family: 'Francois One', sans-serif;
    font-size: 2.4rem;
    color: #344a5e;
    font-style: normal;
    font-weight: 400;
    line-height: 1.18;
`;

const Description = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    color: #344a5e;
`;

const DescriptionContent = styled.div`
    max-width: 750px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    hyphens: none;
    line-height: 1.56;
    padding: 0px 10px;
`;

const DescriptionNoMargin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: #344a5e;
`;

const CTA = styled.div`
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
`;

const Link = styled.a`
    background-color: #ffc713;
    color: white;
    border-radius: 999px;
    font-size: 1rem;
    padding: 0.91rem 1.36rem;
    text-decoration: none;
`;

const useStyles = makeStyles(() => ({
    mainContainer: {
        marginTop: 20,
        margin: 0,
        width: '100%',
    },
    topContainer: {
        minHeight: '6vh',
        margin: 0,
        width: '100%',
    },
    middleContainer: {
        margin: 0,
        // marginTop: '50px',
        width: '99%',
    },
    image: {
        backgroundImage: 'url("backgroundLogin.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundPositionY: "10%"
        // backgroundPositionX: "-200px"
    },
    logo: {
        paddingLeft: theme.spacing(10),
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(8),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(0),
        },
    },
    docs: {
        paddingRight: theme.spacing(10),
        [theme.breakpoints.down('lg')]: {
            paddingRight: theme.spacing(8),
        },
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(0),
        },
    },
    containerLogo: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        paddingTop: 5,
    },
    imageOnTop: {
        minWidth: '90%',
        maxWidth: '90%',
        position: 'relative',
        top: '200px',
        left: '10%',
    },
    successMessage: {
        color: '#49941b',
        fontWeight: 600,
    },
}));

interface RegistrationWrapperProps {
    isSportsbook?: boolean;
    loginForm?: React.ReactNode;
    children?: React.ReactNode;
}

function RegistrationWrapper(props: RegistrationWrapperProps) {
    const classes = useStyles();
    const { children, isSportsbook } = props;

    return (
        <div
            style={{
                display: 'flex',
                height: '98vh',
                width: '100%',
                background:
                    isSportsbook === true
                        ? `white`
                        : 'linear-gradient(180deg, rgba(156,185,201,1) 0%, rgba(183,204,216,1) 60%, rgba(250, 250, 250, 1) 60%)',
            }}
        >
            <Grid
                container
                className={classes.middleContainer}
                justifyContent="center"
                style={{
                    ...(isSportsbook === true && {
                        background: `url(${sportsbookBackground}) no-repeat`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '60%',
                    }),
                }}
            >
                <Hidden smDown>
                    <Grid item xs={false} sm={false} md={7} lg={7} xl={7}>
                        <div
                            style={{
                                minHeight: '60%',
                                height: '60vh',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {!isSportsbook && (
                                <div style={{}}>
                                    <Title>THEOplayer portal</Title>
                                    <Description>
                                        <DescriptionContent>
                                            <p>
                                                Welcome to THEOportal, our self-service platform that allows you to get
                                                started with THEOplayer, our award-winning video player, which brings
                                                cross-platform video playback efficiently on any device.
                                            </p>
                                            <p>
                                                With 10+ years of experience supporting Tier 1 companies, we have earned
                                                the trust of global players, including Vodafone, A&E, and Telenor.
                                                Whether you're launching on web and mobile platforms or exploring
                                                advanced use cases such as smart TVs and set-top-boxes, we've got you
                                                covered! Consult with our experts to discuss your specific streaming
                                                requirements.
                                            </p>
                                        </DescriptionContent>
                                    </Description>
                                    <CTA>
                                        <Link href="https://www.theoplayer.com/contact-us">
                                            No account yet? Talk with Our Experts!
                                        </Link>
                                    </CTA>
                                </div>
                            )}
                        </div>
                        <div style={{ height: '35%', textAlign: 'center' }}>
                            <Title>
                                {isSportsbook ? 'THEOplayer is trusted by leading companies' : 'Companies who trust us'}
                            </Title>
                            <DescriptionNoMargin>
                                {!isSportsbook && (
                                    <DescriptionContent>
                                        Trusted by leading media and entertainment companies, delivering cutting-edge
                                        video services efficiently and on any device.
                                    </DescriptionContent>
                                )}
                                <div
                                    style={{
                                        marginTop: isSportsbook === true ? '0px' : '16px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        columnGap: '32px',
                                        rowGap: '24px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {!isSportsbook ? (
                                        <>
                                            <img src="/telenor.svg" alt="telenor" height={100} width={100} />
                                            <img src="/peacock.svg" alt="peacock" height={100} width={100} />
                                            <img src="/telia.svg" alt="telia" height={100} width={100} />
                                            <img src="/rai.svg" alt="rai" height={100} width={100} />
                                            <img src="/vodafone.svg" alt="vodafone" height={100} width={100} />
                                        </>
                                    ) : (
                                        <>
                                            <img src="/bet365.png" alt="bet365" height={100} width={100} />
                                            <img src="/igame.png" alt="igame" height={100} width={100} />
                                            <img src="/wtv.png" alt="wtv" height={100} width={100} />
                                            <img src="/eqify.png" alt="eqify" height={100} width={100} />
                                            <img src="/tabcorp.png" alt="tabcorp" height={100} width={100} />
                                        </>
                                    )}
                                </div>
                            </DescriptionNoMargin>
                        </div>
                    </Grid>
                </Hidden>
                {/* <Grid item md={1} implementation="css" component={Hidden} /> */}
                <Grid item xs={12} sm={8} md={5} lg={5} xl={5} style={{ padding: '50px 0px' }}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
}

export default RegistrationWrapper;
