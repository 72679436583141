import { createStyles, Icon, IconButton, makeStyles, TableCell, TableRow, Theme, Tooltip } from '@material-ui/core';
import { gte } from 'semver';
import { MoreHorizOutlined, SettingsOutlined, WarningOutlined, Widgets } from '@material-ui/icons';
import { navigate } from '@reach/router';
import moment from 'moment';
import * as React from 'react';
import { BuildStatus, ContractType, SdkBuildMechanismType, SdkType } from '../../__generated__/globalTypes';
import { showIcon } from '../PlayerTitle/iconShower';
import {
    CompanyPlayersQuery_company,
    CompanyPlayersQuery_company_sdks,
    CompanyPlayersQuery_company_sdks_buildHistory
} from './__generated__/CompanyPlayersQuery';
import { AuthService } from '../../auth';
import { impressionsConverter } from '../UsageStatistics/ImpressionsConverter';

interface Props {
    player: CompanyPlayersQuery_company_sdks;
    company: CompanyPlayersQuery_company;
    showingLicenses: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        external: {
            fontWeight: 'bold'
        },
        tableRow: {
            cursor: 'pointer'
        },
        actionsBtn: {
            padding: 8
        }
    })
);

function PlayerRow({ player, company, showingLicenses }: Props) {
    const classes = useStyles();
    const { text: statusText, color: statusColor } = getBuildStatusDetails(player);

    const { id: companyId, contract } = company;

    const { isUsingLicense, isLicenseOnly, isTestSdk } = player;

    const isAdmin = AuthService.isAdmin();

    function onPlayerClick(playerId: string) {
        navigate(`./players/${playerId}`);
    }

    function onSettingsClick(playerId: string) {
        navigate(`/app/${companyId}/players/${playerId}`);
    }

    const buildWillExpire = getBuildWillExpire(player, company);

    const domainsShouldBeVerified =
        !player.isExternal &&
        player.isActive &&
        !player.defaultConfiguration.domainsVerified &&
        player.version &&
        gte(player.version, '2.78.0');

    return (
        <TableRow hover className={classes.tableRow} key={player.name} onClick={(event) => onPlayerClick(player.id)}>
            <TableCell align="center">
                {!player.isActive && !player.isDeleted && 'ARCHIVED'}
                {player.isActive && (
                    <React.Fragment>
                        <Tooltip title={statusText} placement="top">
                            <svg width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="7" fill={statusColor} />
                            </svg>
                        </Tooltip>
                        {buildWillExpire && (
                            <Tooltip title={'License will expire in less than a week!'} placement="top">
                                <WarningOutlined
                                    fontSize="small"
                                    color="secondary"
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                            </Tooltip>
                        )}
                        {domainsShouldBeVerified && (
                            <Tooltip title={'Please verify the domains of this player.'} placement="top">
                                <WarningOutlined
                                    fontSize="small"
                                    color="secondary"
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                            </Tooltip>
                        )}
                    </React.Fragment>
                )}
                {!player.isActive && player.isDeleted && 'DELETED'}
            </TableCell>
            <TableCell align="center">{showIcon(player.sdkType, 35)}</TableCell>
            <TableCell align="left">
                <React.Fragment>
                    {player.isTestSdk && (
                        <Tooltip placement="top" title="Test Player SDK">
                            <Icon
                                style={{
                                    marginRight: '10px',
                                    fontSize: '16px'
                                }}
                            >
                                <Widgets color="secondary" fontSize="small" style={{ fontSize: '16px' }} />
                            </Icon>
                        </Tooltip>
                    )}
                    {player.name} {player.isExternal ? <span className={classes.external}> - EXTERNAL</span> : ''}
                </React.Fragment>
            </TableCell>
            <TableCell align="center">
                {showingLicenses ? (
                    <i>Click to view</i>
                ) : player.isExternal ? (
                    player.buildHistory[0].legacyKey
                ) : (
                    player.id
                )}
            </TableCell>
            {!showingLicenses && <TableCell align="center">{player.version}</TableCell>}
            <TableCell align="center">
                {getExpirationDate({
                    isUsingLicense,
                    isLicenseOnly,
                    gracePeriodInDays: contract.gracePeriodInDays,
                    isTestSdk,
                    lastBuildExpirationDate: player.buildHistory[0]?.expiresAt,
                    lastLicenseExpirationDate: player.licenses[0]?.expirationDate,
                    sdkType: player.sdkType
                })}
            </TableCell>
            {isAdmin && !showingLicenses && (
                <TableCell align="center">{player.isUsingLicense ? 'Yes' : 'No'}</TableCell>
            )}
            <TableCell align="center">{impressionsConverter(player.impressionsMonth)}</TableCell>
            <TableCell align="center">
                <Tooltip title="Configure">
                    <IconButton
                        className={classes.actionsBtn}
                        size="small"
                        disabled={player.isExternal || !player.isActive}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSettingsClick(player.id);
                        }}
                    >
                        <SettingsOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Overview">
                    <IconButton
                        className={classes.actionsBtn}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/app/${companyId}/players/${player.id}`);
                        }}
                    >
                        <MoreHorizOutlined />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default PlayerRow;

function getBuildStatusDetails(player: CompanyPlayersQuery_company_sdks): { text: string; color: string } {
    if (player.isLicenseOnly) {
        return {
            text: 'License Only',
            color: '#237539'
        };
    }
    if (player.buildMechanism === SdkBuildMechanismType.NEW) {
        return {
            text: 'Ready to use',
            color: '#237539'
        };
    }
    const buildHistory: CompanyPlayersQuery_company_sdks_buildHistory | undefined = player.buildHistory[0];
    if (!buildHistory) {
        return { text: '', color: '' };
    }

    switch (buildHistory.status) {
        case BuildStatus.PENDING:
        case BuildStatus.QUEUED:
            return {
                text: 'Building...',
                color: '#787878'
            };
        case BuildStatus.IN_PROGRESS:
            return {
                text: 'Building...',
                color: '#344A5E'
            };
        case BuildStatus.SUCCESS:
            return {
                text: 'Ready to use',
                color: '#237539'
            };
        case BuildStatus.FAILED:
        case BuildStatus.ABORTED:
            return {
                text: 'Failed',
                color: '#eb4034'
            };
    }
}

interface GetExpirationDateArgs {
    isUsingLicense: boolean;
    isLicenseOnly: boolean;
    gracePeriodInDays: number;
    isTestSdk: boolean;
    sdkType: SdkType;
    lastBuildExpirationDate?: string;
    lastLicenseExpirationDate?: string;
}

// Make our own args interface: will be used by multiple components, which will use different GraphQL queries (different props to be accessed)
// This might cause type problems when re-using this function
export function getExpirationDate({
    isUsingLicense,
    isLicenseOnly,
    gracePeriodInDays,
    isTestSdk,
    lastBuildExpirationDate,
    lastLicenseExpirationDate,
    sdkType
}: GetExpirationDateArgs): string {
    const gracePeriod = isTestSdk ? 0 : gracePeriodInDays;

    if (isUsingLicense || isLicenseOnly || sdkType === SdkType.ROKU) {
        return moment(lastLicenseExpirationDate!).subtract(gracePeriod, 'days').format('LL');
    }

    return lastBuildExpirationDate ? moment(lastBuildExpirationDate).subtract(gracePeriod, 'days').format('LL') : '';
}

function getBuildWillExpire(player: CompanyPlayersQuery_company_sdks, company: CompanyPlayersQuery_company): boolean {
    const isPayg = company.contract.contractType === ContractType.TRIAL;
    const { buildHistory } = player;

    if (isPayg || player.buildHistory.length === 0 || player.isUsingLicense) {
        return false;
    }

    const lastBuild = buildHistory[0];
    const startDate = moment(lastBuild.expiresAt).subtract(7, 'days');

    return moment().isSameOrAfter(startDate);
}
