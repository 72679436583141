import { Button, createStyles, FormControl, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import gql from 'graphql-tag';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import useForm from 'react-hook-form';
import { getValidationError } from '../../../apollo/errors';
import { PLAYER_DETAIL_QUERY } from '../../PlayerInfo/PlayerDetailsQuery';
import { PlayerDetailQuery, PlayerDetailQueryVariables } from '../../PlayerInfo/__generated__/PlayerDetailQuery';
import { COMPANY_PLAYERS_QUERY } from '../../PlayerOverview/MyPlayers';
import Spinner from '../../Spinner';
import { DuplicatePlayerMutation, DuplicatePlayerMutationVariables } from './__generated__/DuplicatePlayerMutation';

interface InputProps {
    companyId: string;
    playerId: string;
    onClose: () => void;
}

interface FormData {
    name: string;
}

export const DUPLICATE_PLAYER_MUTATION = gql`
    mutation DuplicatePlayerMutation($input: DuplicatePlayerInput!) {
        duplicatePlayer(input: $input) {
            id
        }
    }
`;

export const GET_PLAYER_QUERY = gql`
    query GetPlayerQuery($id: ID!) {
        sdk(id: $id) {
            id
            name
            version
            isExternal
            isActive
            isDeleted
            defaultConfiguration {
                features
            }
        }
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexit: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px'
        },
        formBtn: {
            display: 'flex',
            marginLeft: '10px'
        }
    })
);

type Props = InputProps & WithSnackbarProps;

function DuplicatePlayerForm(props: Props) {
    const { companyId, playerId, onClose } = props;
    const classes = useStyles();

    const { handleSubmit, register, errors } = useForm<FormData>();
    const [error, setError] = React.useState('');

    const { loading, data } = useQuery<PlayerDetailQuery, PlayerDetailQueryVariables>(PLAYER_DETAIL_QUERY, {
        variables: {
            playerId: playerId
        }
    });
    const [duplicatePlayerMut] = useMutation<DuplicatePlayerMutation, DuplicatePlayerMutationVariables>(
        DUPLICATE_PLAYER_MUTATION
    );

    if (loading || !data) {
        return <Spinner />;
    }

    const duplicatePlayer = handleSubmit(async ({ name }: FormData) => {
        setError('');
        try {
            const res = await duplicatePlayerMut({
                variables: {
                    input: {
                        playerId,
                        name
                    }
                },
                refetchQueries: [
                    {
                        query: COMPANY_PLAYERS_QUERY,
                        variables: {
                            companyId
                        }
                    }
                ]
            });
            onSuccess(res.data!.duplicatePlayer.id);
        } catch (e) {
            onError(e);
        }
    });

    function onSuccess(newPlayerId: string) {
        const { enqueueSnackbar } = props;
        enqueueSnackbar('Duplicated player', {
            variant: 'success'
        });
        navigate(`/app/${companyId}/players/${newPlayerId}`);
        onClose();
    }

    function onError(e: any) {
        const { enqueueSnackbar } = props;

        const dupError = getValidationError(e, {
            type: 'DUPLICATE_NAMES_NOT_ALLOWED'
        });

        if (dupError && dupError.message) {
            setError('Duplicate names not allowed');
        }

        enqueueSnackbar('An error has occured', {
            variant: 'error'
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item container>
                <Typography variant="subtitle2" color="error">
                    {error}
                </Typography>
                <form
                    onSubmit={handleSubmit((data1, e) => duplicatePlayer(e))}
                    style={{ width: '100%', marginTop: '15px' }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputRef={register({
                                        required: 'Name is required'
                                    })}
                                />
                                <Typography variant="subtitle2" color="error">
                                    {errors.name && errors.name.message}
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.flexit}>
                                <Button
                                    variant="contained"
                                    onClick={() => onClose()}
                                    className={classes.formBtn}
                                    type="button"
                                >
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" className={classes.formBtn} type="submit">
                                    Duplicate
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default withSnackbar(DuplicatePlayerForm);
