import * as React from "react";

import { CircularProgress } from "@material-ui/core";
import { StyleProps } from "../../utils/types";

function Spinner({ className }: StyleProps) {
    return (
        <CircularProgress
            className={className}
            style={{ color: "#FFC107" }}
            thickness={3}
            size={25}
        />
    );
}

export default Spinner;
