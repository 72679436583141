import * as React from "react";

import { CircularProgress, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px"
    }
}));

function PageSpinner() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <CircularProgress
                style={{ color: "#FFC107" }}
                thickness={4}
                size={45}
            />
        </div>
    );
}

export default PageSpinner;
