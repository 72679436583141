export interface UserProfile {
    id: string;
    username: string;
    company: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    isAdminReadOnly: boolean;
    isCustomerReadOnly: boolean;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isResellerAccount: boolean;
    isEnterpriseUser: boolean;
    isPaygUser: boolean;
    picture: string;
    isAnalytics: boolean;
    isActivated: boolean;
    isAmLead: boolean;
    isFirstLogin: boolean;
    accountType: 'TRIAL' | 'ENTERPRISE' | 'ADMIN' | 'SPORTSBOOK';
    needsToAcceptSportsbookTC: boolean;
}

export type AuthResult = Readonly<{
    accessToken: string;
    idToken: string;
    idTokenPayload?: {
        aud: string;
        exp: number;
        iat: number;
        iss: string;
        sub: string;
    };
    refreshToken?: string;
    state?: string;
    expiresIn: number;
    tokenType: string;
    scope: string;
}>;

export { default as AuthService } from './AuthService';
