import {
    Button,
    Grid,
    Table,
    TableBody,
    TableHead,
    Typography,
    makeStyles,
    Theme,
    createStyles
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { navigate } from '@reach/router';
import * as React from 'react';
import { CompanyPlayersQuery_company, CompanyPlayersQuery_company_sdks } from './__generated__/CompanyPlayersQuery';
import PlayerRow from './PlayerRow';
import PlayersHeaderRow from './PlayersHeaderRow';

interface Props {
    company: CompanyPlayersQuery_company;
    playerStatusToShow: string;
    canBuild: boolean;
    showLicenseOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emptyTable: {
            height: '300px'
        },
        emptyTableItem: {
            textAlign: 'center',
            margin: theme.spacing(1)
        }
    })
);

function MyPlayersOverview({ showLicenseOnly, company, playerStatusToShow, canBuild }: Props) {
    const classes = useStyles();

    const sdks: CompanyPlayersQuery_company_sdks[] =
        playerStatusToShow === 'deleted'
            ? company.sdks.filter((s) => s.isDeleted && !s.isActive)
            : playerStatusToShow === 'archived'
            ? company.sdks.filter((s) => !s.isActive && !s.isDeleted)
            : company.sdks.filter((s) => s.isActive);

    const sdksToShow: CompanyPlayersQuery_company_sdks[] = sdks.filter((s) =>
        showLicenseOnly ? s.isLicenseOnly : !s.isLicenseOnly
    );

    return (
        <React.Fragment>
            <Table size="small" aria-label="simple table">
                {sdksToShow.length > 0 && (
                    <React.Fragment>
                        <TableHead>
                            <PlayersHeaderRow showingLicenses={showLicenseOnly} />
                        </TableHead>
                        <TableBody>
                            {sdksToShow.map((sdk) => {
                                return (
                                    <PlayerRow
                                        player={sdk}
                                        company={company}
                                        key={sdk.name}
                                        showingLicenses={showLicenseOnly}
                                    />
                                );
                            })}
                        </TableBody>
                    </React.Fragment>
                )}
            </Table>
            {sdksToShow.length === 0 && (
                <Grid container justifyContent="center" alignContent="center" className={classes.emptyTable}>
                    <Grid item xs={12} className={classes.emptyTableItem}>
                        <Typography color="primary">
                            {`You have no ${playerStatusToShow} ${showLicenseOnly ? 'license only' : ''} players.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.emptyTableItem}>
                        {canBuild && (
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() =>
                                    navigate(`./players/new${!showLicenseOnly ? '?buildMethod=custom' : ''}`)
                                }
                            >
                                Create
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default MyPlayersOverview;
