import { createStyles, makeStyles, TableCell, TableRow, Theme, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { AuthService } from '../../auth';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        th: {
            color: '#999999'
        },
        thAction: {
            color: '#999999',
            width: '20%'
        }
    })
);

interface Props {
    showingLicenses: boolean;
}

function PlayersHeaderRow({ showingLicenses }: Props) {
    const classes = useStyles();

    const isAdmin = AuthService.isAdmin();

    return (
        <TableRow>
            <TableCell className={classes.th} align="center">
                Status
            </TableCell>
            <TableCell className={classes.th} align="center">
                Platform
            </TableCell>
            <TableCell className={classes.th} align="left">
                Name
            </TableCell>
            <TableCell className={classes.th} align="center">
                {showingLicenses ? 'License' : 'Key'}
            </TableCell>
            {!showingLicenses && (
                <TableCell align="center" className={classes.th}>
                    Version
                </TableCell>
            )}
            <TableCell className={classes.th} align="center">
                Expires on
            </TableCell>
            {isAdmin && !showingLicenses && (
                <TableCell className={classes.th} align="center">
                    Using license?
                </TableCell>
            )}
            <Tooltip placement="top" title="Usage of current month">
                <TableCell className={classes.th} align="center">
                    Usage
                </TableCell>
            </Tooltip>
            <TableCell className={classes.thAction} align="center">
                Actions
            </TableCell>
        </TableRow>
    );
}

export default PlayersHeaderRow;
