declare global {
    interface Window {
        config: string;
    }
}

const KEY: string | undefined = process.env.REACT_APP_SECRET;

// this method deobfuscates the client configuration and exports it
// benefits of this approach:
//   * makes it harder, although not impossible, to steal API keys and such
//   * prevents changing the configuration from the developer console
const getConfig = (): Record<string, string> => {
    try {
        if (window.config && KEY) {
            const data = atob(window.config);
            let decoded = [];
            for (let i = 0; i < data.length; ++i) {
                decoded.push(data.charCodeAt(i) ^ KEY.charCodeAt(i % KEY.length));
            }
            return JSON.parse(String.fromCharCode(...decoded)) as Record<string, string>;
        }
    } catch (e) {
        // do nothing
        // there is no configuration, so we can't notify Sentry either
        // we could consider configuring a fallback in this case
    }
    return {};
};

export const env: Record<string, string> = getConfig();
