/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdvancedSearchType {
  AUTH0_ID = "AUTH0_ID",
  EMAIL = "EMAIL",
  LICENSE_KEY = "LICENSE_KEY",
  SDK_ID = "SDK_ID",
}

export enum AuthLogType {
  LOGIN_FAIL_OTHER = "LOGIN_FAIL_OTHER",
  LOGIN_FAIL_PASSWORD = "LOGIN_FAIL_PASSWORD",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
}

export enum BuildStatus {
  ABORTED = "ABORTED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  QUEUED = "QUEUED",
  SUCCESS = "SUCCESS",
}

export enum BuyableContractType {
  PROFESSIONAL = "PROFESSIONAL",
  STARTER = "STARTER",
}

export enum CancelationReasonType {
  DIFFERENT_SOLUTION = "DIFFERENT_SOLUTION",
  MISSING_FEATURES = "MISSING_FEATURES",
  OTHER = "OTHER",
  SHORT_TERM_PROJECT = "SHORT_TERM_PROJECT",
  TECHNICAL_ISSUE = "TECHNICAL_ISSUE",
  TOO_EXPENSIVE = "TOO_EXPENSIVE",
}

export enum CompanyState {
  RENEWING_LICENCES = "RENEWING_LICENCES",
  STANDARD = "STANDARD",
}

export enum ContractDurationType {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum ContractType {
  ENTERPRISE = "ENTERPRISE",
  NEW_CUSTOMER = "NEW_CUSTOMER",
  PROFESSIONAL = "PROFESSIONAL",
  SPORTSBOOK = "SPORTSBOOK",
  STARTER = "STARTER",
  TRIAL = "TRIAL",
}

export enum CurrencyType {
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  USD = "USD",
}

/**
 * Collection of features to be connected to a contract
 */
export enum FeatureType {
  hesp = "hesp",
  multiview = "multiview",
  theoads = "theoads",
}

export enum FilterCompaniesType {
  ALL = "ALL",
  CANCELLED = "CANCELLED",
  ENTERPRISE = "ENTERPRISE",
  NEW_CUSTOMER = "NEW_CUSTOMER",
  PARTNERSHIP = "PARTNERSHIP",
  PROFESSIONAL = "PROFESSIONAL",
  RESELLER = "RESELLER",
  SPORTSBOOK = "SPORTSBOOK",
  STARTER = "STARTER",
  TRIAL = "TRIAL",
}

export enum LicenseBuildReason {
  CONTRACT_UPDATE = "CONTRACT_UPDATE",
  CREATE = "CREATE",
  RENEW = "RENEW",
  USER_UPDATE = "USER_UPDATE",
}

export enum LicenseSdkType {
  android = "android",
  androidtv = "androidtv",
  chromecast = "chromecast",
  chromecastcaf = "chromecastcaf",
  firetv = "firetv",
  html5 = "html5",
  ios = "ios",
  kepler = "kepler",
  roku = "roku",
  theoliveweb = "theoliveweb",
  tizen = "tizen",
  tvos = "tvos",
  webos = "webos",
}

export enum PackagePricingVersionType {
  DEC2021 = "DEC2021",
  DEC2022 = "DEC2022",
}

export enum SdkBuildMechanismType {
  NEW = "NEW",
  OLD = "OLD",
}

export enum SdkType {
  ANDROID = "ANDROID",
  ANDROID_TV = "ANDROID_TV",
  CHROMECAST = "CHROMECAST",
  CHROMECAST_CAF = "CHROMECAST_CAF",
  FIRE_TV = "FIRE_TV",
  FLUTTER = "FLUTTER",
  IOS = "IOS",
  IPAD_OS = "IPAD_OS",
  KEPLER = "KEPLER",
  REACT_NATIVE = "REACT_NATIVE",
  ROKU = "ROKU",
  SPORTSBOOK = "SPORTSBOOK",
  TIZEN = "TIZEN",
  TV_OS = "TV_OS",
  WEB = "WEB",
  WEB_OS = "WEB_OS",
}

export enum SetupType {
  ADVANCED = "ADVANCED",
  BASIC = "BASIC",
}

export enum SupportPlanType {
  BASIC = "BASIC",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
  PREMIER = "PREMIER",
  STANDARD = "STANDARD",
}

export enum TierType {
  SUPPORT_BUSINESS = "SUPPORT_BUSINESS",
  SUPPORT_ENTERPRISE = "SUPPORT_ENTERPRISE",
  SUPPORT_PREMIER = "SUPPORT_PREMIER",
  SUPPORT_STANDARD = "SUPPORT_STANDARD",
  _0_10K = "_0_10K",
  _100K_500K = "_100K_500K",
  _10K_100K = "_10K_100K",
  _1M_5M = "_1M_5M",
  _500K_1M = "_500K_1M",
  _5M_MORE = "_5M_MORE",
}

export enum UsageImpressionType {
  ADS = "ADS",
  NORMAL = "NORMAL",
}

export enum UsageType {
  DAY = "DAY",
  HOUR = "HOUR",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum UserAccountRole {
  admin = "admin",
  admin_read_only = "admin_read_only",
  am_lead = "am_lead",
  analytics = "analytics",
  customer = "customer",
  customer_read_only = "customer_read_only",
  enterprise = "enterprise",
  hesp_admin = "hesp_admin",
  hesp_superadmin = "hesp_superadmin",
  hesp_theo_admin = "hesp_theo_admin",
  hesp_theo_admin_sales = "hesp_theo_admin_sales",
  hesp_user = "hesp_user",
  pay_as_you_go = "pay_as_you_go",
  reseller = "reseller",
  superadmin = "superadmin",
  theolive_service = "theolive_service",
}

export interface AbortBitriseBuildInput {
  buildId: string;
}

export interface ActivateUserInput {
  email: string;
  code: string;
  type: ContractType;
  password: string;
  confirmPassword: string;
}

export interface AddEnterpriseCompanyInput {
  name: string;
  mainEmail: string;
  parentId: string;
  isReseller: boolean;
  isPartner: boolean;
  contractStart: any;
  contractUntil: any;
}

export interface AddIframePlayerInput {
  playerId: string;
}

export interface AddPaymentMethodInput {
  companyId?: string | null;
  paymentMethodId?: string | null;
}

export interface AddSportsBookCompanyInput {
  name: string;
  mainEmail: string;
  contractUntil: any;
}

export interface AddTrialCompanyInput {
  name: string;
  mainContactEmail: string;
  mainEmail: string;
  contractUntil: any;
}

export interface AddVatToCustomerInput {
  stripeId: string;
  vatNumber: string;
}

export interface BlockCompanyInput {
  companyId: string;
}

export interface BuyPackageInput {
  companyId: string;
  package: BuyableContractType;
  durationType: ContractDurationType;
}

export interface CancelPackageInput {
  companyId: string;
  reasons: CancelationReasonType[];
}

export interface CancelRenewProcessInput {
  companyId: string;
}

export interface ChangeSupportPlanInput {
  companyId: string;
  supportPlan: string;
}

export interface ChangeUserSettingsInput {
  userId: string;
  isReadOnly: boolean;
  isAM?: boolean | null;
}

export interface CheckAccountToLinkInput {
  auth0Id: string;
}

export interface ClonePlayerInput {
  buildId: string;
  companyId: string;
  name: string;
}

export interface ConnectResellerPartnerInput {
  companyId: string;
  connectAsReseller: boolean;
  connectAsPartner: boolean;
}

export interface ContractPeriodInput {
  from: any;
  until: any;
  isPerpetual: boolean;
  gracePeriodInDays: number;
}

export interface CreateLegacyPlayerInput {
  companyId: string;
  isTestSdk: boolean;
  name: string;
  sdkType: SdkType;
  legacyKey: string;
  cdnZipLink?: string | null;
  expiresAt?: any | null;
  version?: string | null;
}

export interface CreatePlayerInput {
  companyId: string;
  isTestSdk: boolean;
  name: string;
  sdkType?: SdkType | null;
  version?: string | null;
  isAlwaysLatest: boolean;
  features: string[];
  pageDomains: string[];
  sourceDomains: string[];
  setupType: SetupType;
  customisation?: string | null;
  isLicenseOnly: boolean;
}

export interface CreateUserAccountInput {
  companyId: string;
  email: string;
  readOnly: boolean;
}

export interface DeleteCompanyInput {
  companyId: string;
}

export interface DeletePlayerInput {
  playerId: string;
}

export interface DisablePlayerInput {
  playerId: string;
}

export interface DisconnectResellerPartnerInput {
  companyId: string;
  disconnectAsReseller: boolean;
  disconnectAsPartner: boolean;
}

export interface DuplicatePlayerInput {
  playerId: string;
  name: string;
}

export interface EnablePlayerInput {
  playerId: string;
}

export interface ExtendContractInput {
  companyId: string;
  period: ContractPeriodInput;
}

export interface GetYouboraAccountInput {
  companyId: string;
}

export interface ManageBillingAddressInput {
  companyId: string;
  street: string;
  country: string;
  city: string;
  zip: string;
  state: string;
  currency: CurrencyType;
}

export interface MergeCompanyInput {
  companyId: string;
  companyMergedId: string;
}

export interface MutationActivatedAccountInput {
  email: string;
}

export interface MutationChangePasswordInput {
  password: string;
}

export interface PricingRowInput {
  tier?: TierType | null;
  priceEuro?: number | null;
  priceUsd?: number | null;
  pricePound?: number | null;
  priceYen?: number | null;
}

export interface PublishInput {
  playerId: string;
}

export interface ReactivateCancelledPackageInput {
  companyId: string;
}

export interface ReactivateContractInput {
  companyId: string;
}

export interface RemovePaymentMethodsToCustomerInput {
  stripeId: string;
}

export interface RemoveUserAccountInput {
  userId: string;
}

export interface RemoveVatFromCompanyInput {
  companyId: string;
}

export interface ResendActivationEmailInput {
  userId: string;
}

export interface StartRenewProcessInput {
  companyId: string;
}

export interface SwitchContractTypeInput {
  companyId: string;
  contractTypeToChangeTo?: ContractType | null;
  durationTypeToChangeTo?: ContractDurationType | null;
  startDate?: any | null;
  untilDate?: any | null;
  packagePricingVersion?: PackagePricingVersionType | null;
}

export interface TerminateContractInput {
  companyId: string;
}

export interface TransferUserInput {
  userId: string;
  companyId: string;
}

export interface UnblockCompanyInput {
  companyId: string;
}

export interface UpdateCompanyDataInput {
  companyId: string;
  name: string;
  logoUrl?: string | null;
  primaryEmail?: string | null;
}

export interface UpdateCompanySettingsInput {
  companyId: string;
  parentId: string;
  companyOwnerId?: string | null;
}

export interface UpdateContractInput {
  companyId: string;
  sdkTypes?: SdkType[] | null;
  impressionsLimit?: any | null;
  licenseCost?: number | null;
  overageCost?: number | null;
  fromDate: any;
  untilDate: any;
  features?: FeatureType[] | null;
  currency: CurrencyType;
  storeLogs: boolean;
  theoAds: boolean;
  theoAdsImpressionsLimit?: any | null;
  theoAdsOverageCost?: number | null;
}

export interface UpdateLegacyPlayerInput {
  sdkId: string;
  name: string;
  legacyKey: string;
  cdnZipLink?: string | null;
  expiresAt?: any | null;
  version?: string | null;
}

export interface UpdatePlayerConfigurationInput {
  playerId: string;
  sourceDomains: string[];
  pageDomains: string[];
  features: string[];
  customisation?: string | null;
  version: string;
  isAlwaysLatest: boolean;
  setupType?: SetupType | null;
}

export interface UpdatePlayerInput {
  playerId: string;
  name: string;
}

export interface UpdatePricingInput {
  pricingRows: PricingRowInput[];
}

export interface UpdateProfileDataInput {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface VerifyPlayerDomainsInput {
  sdkId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
