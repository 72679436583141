import _ from "lodash";
import { UsageEntry } from "../UsageGraph/UsageCombinationData";

export const impressionsConverter = (impressions: number) => {
    if (impressions > 1000000000) {
        return (impressions / 1000000000).toFixed(1) + "b";
    }
    if (impressions > 1000000) {
        return (impressions / 1000000).toFixed(1) + "m";
    }
    if (impressions > 10000) {
        return (impressions / 1000).toFixed(1) + "k";
    }
    return impressions.toString();
};

export function getTotalImpressions(entries: UsageEntry[]): number {
    if (!entries) {
        return 0;
    }
    const structured = entries.map(entry =>
        entry.impressions.map(impr => impr.count)
    );
    const flatted = _.flatMap(structured);
    const total = flatted.reduce((a, b) => a + b, 0);

    return total;
}
