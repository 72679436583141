import * as React from "react";

import classNames from "classnames";

import { SdkType, BuildStatus } from "../../__generated__/globalTypes";
import { PlayerCardTypes, PlayerPlaceholder } from "./PlayerCardLabel";

import {
    Card,
    CardActionArea,
    makeStyles,
    Theme,
    Grid
} from "@material-ui/core";
import { isUndefined } from "lodash";
import { StyleProps } from "../../utils/types";
import { Cached } from "@material-ui/icons";
import gql from "graphql-tag";
import { PlayerBuildProgressFragment } from "./__generated__/PlayerBuildProgressFragment";
import { FragmentProps } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => ({
    playerCard: {
        height: "200px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    },
    lightBackground: {
        backgroundColor: theme.palette.secondary.main
    },
    darkBackground: {
        backgroundColor: theme.palette.primary.main
    },
    greyBackground: {
        backgroundColor: theme.palette.grey[300]
    },
    buildingContainer: {
        position: "absolute",
        color: "rgba(255,255,255,0.8)",
        textAlign: "center",
        top: 10,
        right: 10,
        height: 50
    },
    buildingIcon: {
        color: theme.palette.secondary.main,
        animationName: "$spin",
        animationDuration: "3000ms",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear"
    },
    "@keyframes spin": {
        from: {
            transform: "rotate(0deg)"
        },
        to: {
            transform: "rotate(360deg)"
        }
    }
}));

export const PLAYER_BUILD_PROGRESS_FRAGMENT = gql`
    fragment PlayerBuildProgressFragment on Build {
        id
        status
        progress
        legacyKey
        expiresAt
    }
`;

interface InputProps {
    cardType: PlayerCardTypes;
    playerId?: string;
    isLegacy?: boolean;
    children?: React.ReactNode;
    build?: FragmentProps<PlayerBuildProgressFragment>;
    onCardClick: (playerId?: string) => void | Promise<void>;
}

type AllProps = InputProps & StyleProps;

function PlayerCard({
    playerId,
    isLegacy,
    cardType,
    onCardClick,
    children,
    className,
    build
}: AllProps) {
    const classes = useStyles();
    const hasLightBg = cardType === PlayerPlaceholder.ADD_PLAYER;
    const hasDarkBg =
        (!isUndefined(SdkType[cardType]) ||
            cardType === PlayerPlaceholder.DEMO_PLAYER) &&
        !isLegacy;

    return (
        <Card
            className={classNames(classes.playerCard, className, {
                [classes.greyBackground]: isLegacy,
                [classes.darkBackground]: hasDarkBg,
                [classes.lightBackground]: hasLightBg
            })}
            onClick={() => onCardClick(playerId)}
        >
            <CardActionArea>
                {build &&
                    build.status &&
                    (build.status === BuildStatus.IN_PROGRESS ||
                        build.status === BuildStatus.QUEUED) && (
                        <div className={classes.buildingContainer}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>{build.status}...</Grid>
                                <Grid item>
                                    <Cached className={classes.buildingIcon} />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                {children}
            </CardActionArea>
        </Card>
    );
}

export default PlayerCard;
