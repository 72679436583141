import { Grid, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { RouteComponentProps } from '@reach/router';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as React from 'react';
import { gte } from 'semver';
import { useQuery } from 'react-apollo';
import { GET_PLAYER_QUERY } from '../../components/PlayerManagementDialogs/DuplicatePlayerDialog/DuplicatePlayerForm';
import {
    GetPlayerQuery,
    GetPlayerQueryVariables
} from '../../components/PlayerManagementDialogs/DuplicatePlayerDialog/__generated__/GetPlayerQuery';
import Spinner from '../../components/Spinner';
import { env } from '../../utils/env';

interface Props extends RouteComponentProps {
    sdkId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.primary.main,
        paddingBottom: '20px',
        fontWeight: 600
    },
    container: {
        marginTop: theme.spacing(10)
    },
    paragraph: {
        fontSize: '16px'
    },
    item: {
        paddingBottom: '20px'
    },
    icon: {
        fontSize: 60,
        color: theme.palette.secondary.main,
        margin: theme.spacing(2)
    }
}));

function DownloadPage({ sdkId }: Props) {
    const classes = useStyles();
    const url = `${env.CDN_URL}/${sdkId}/`;
    const zip = new JSZip();
    const folder = zip.folder('THEOplayer');

    const { loading, data } = useQuery<GetPlayerQuery, GetPlayerQueryVariables>(GET_PLAYER_QUERY, {
        variables: {
            id: sdkId!
        }
    });

    if (loading || !data) {
        return <Spinner />;
    }

    const files = [
        'THEOplayer.js',
        'THEOplayer.chromeless.js',
        'iframe.html',
        'theoplayer.d.js',
        'theoplayer.e.js',
        'theoplayer.p.js'
    ];

    if (data.sdk.defaultConfiguration.features.includes('ui')) {
        files.push('ui.css');
    }

    if (data.sdk.version !== null && gte(data.sdk.version, '2.83.0')) {
        files.push('THEOplayer.d.ts');
    }

    if (
        data.sdk.version !== null &&
        gte(data.sdk.version, '3.4.0') &&
        data.sdk.defaultConfiguration.features.includes('ll-hls')
    ) {
        files.push('THEOplayer.transmux.asmjs.js');
        files.push('THEOplayer.transmux.js');
        files.push('THEOplayer.transmux.wasm');
    }

    for (const f of files) {
        const fullURL = url + f;
        const blobPromise = fetch(fullURL, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }).then((r) => {
            return r.blob();
        });
        if (blobPromise) {
            folder!.file(f, blobPromise);
        }
    }

    zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, 'THEOplayer.zip');
    });

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
            className={classes.container}
        >
            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignContent="center"
                direction="column"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h4" align="center" className={classes.title}>
                        ZIP FILE CREATED!
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className={classes.icon}>
                        <CloudDownload style={{ fontSize: 60 }} />
                    </Icon>
                </Grid>
                <Grid item>
                    <Typography variant="h6" style={{ fontWeight: 300 }}>
                        Your download will start quickly...
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DownloadPage;
