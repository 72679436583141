import * as React from 'react';

import { Redirect, Router } from '@reach/router';

import { AuthService } from './auth';
import PageSpinner from './components/PageSpinner';
import CompanyBlockedPage from './views/Public/CompanyBlockedPage';
import DownloadPage from './views/Public/DownloadPage';
const ProviderCheck = React.lazy(() => import('./views/Public/ProviderCheck'));
const Login = React.lazy(() => import('./views/Public/Login'));
const ProviderConnected = React.lazy(() => import('./views/Public/ProviderConnectedPage'));
const AppPage = React.lazy(() => import('./views/App/AppPage'));
const Logout = React.lazy(() => import('./views/Public/LogoutPage'));
const Error = React.lazy(() => import('./views/Public/ErrorPage'));
const ResetPasswordPage = React.lazy(() => import('./views/Public/ResetPassword'));

export default function Routes() {
    const redirect = AuthService.isAuthenticated() ? '/app' : '/login';
    return (
        <React.Suspense fallback={<PageSpinner />}>
            <Router>
                <Redirect from="/" to={`${redirect}`} noThrow />
                <Redirect from="/getting-started" to={`/login`} noThrow />
                <Error path="error" />
                <Logout path="logout" />
                <ProviderConnected path="oauthconnected" />
                <DownloadPage path="download/:sdkId" />
                <ResetPasswordPage path="reset-password" />
                <Login path="login" />
                <ProviderCheck path="login/callback" />
                <CompanyBlockedPage path="company-blocked" />
                <AppPage path="app" />
                <AppPage path="app/:companyId/*" />
            </Router>
        </React.Suspense>
    );
}
