import * as React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import AppWrapper from './AppWrapper';
import { env } from './utils/env';

SyntaxHighlighter.registerLanguage('javascript', js);

// REACT_APP_VERSION must be set in the environment
// reading it from package.json directly is insecure
const analyticsId = env.ANALYTICS_ID as string;
const tagManagerArgs = {
    gtmId: env.GTM_ID as string
};

ReactGA.initialize(analyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize(tagManagerArgs);

const rootNode = document.getElementById('root');

ReactDOM.render(<AppWrapper />, rootNode);
