import * as React from 'react';
import { SdkType } from '../../__generated__/globalTypes';

export const showIcon = (sdkType: SdkType, height: number) => {
    switch (sdkType) {
        case SdkType.WEB:
            return <img src="/html5.png" alt="html5-sdk" height={height} />;
        case SdkType.ANDROID:
            return <img src="/android.png" alt="android-sdk" height={height} />;
        case SdkType.IOS:
            return <img src="/ios.png" alt="ios-sdk" height={height} />;
        case SdkType.CHROMECAST:
            return <img src="/chromecast.png" alt="chromecast-sdk" height={height} />;
        case SdkType.ANDROID_TV:
            return <img src="/android-tv.png" alt="androidtv-sdk" height={height} />;
        case SdkType.TV_OS:
            return <img src="/tvos.png" alt="tvos-sdk" height={height} />;
        case SdkType.CHROMECAST_CAF:
            return <img src="/chromecast.png" alt="chromecast-sdk" height={height} />;
        case SdkType.IPAD_OS:
            return <img src="/ipados.png" alt="ipad-os-sdk" height={height} />;
        case SdkType.FIRE_TV:
            return <img src="/firetv.png" alt="fire-tv-sdk" height={height} />;
        case SdkType.ROKU:
            return <img src="/roku.png" alt="roku-sdk" height={height} />;
        case SdkType.TIZEN:
            return <img src="/tizen.png" alt="tizen-sdk" height={height} />;
        case SdkType.WEB_OS:
            return <img src="/webos.png" alt="web-os-sdk" height={height} />;
        case SdkType.REACT_NATIVE:
            return <img src="/react-native.png" alt="react-native-sdk" height={height} />;
        case SdkType.FLUTTER:
            return <img src="/flutter.png" alt="flutter-sdk" height={height} />;
        case SdkType.SPORTSBOOK:
            return <img src="/sportsbook.png" alt="sportsbook-sdk" height={height} />;
        case SdkType.KEPLER:
            return <img src="/kepler.png" alt="kepler-sdk" height={height} />;
    }
};
