import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import { navigate } from '@reach/router';
import { AuthService } from '../auth';
import { env } from '../utils/env';

const GRAPHQL_ENDPOINT = env.GRAPHQL_ENDPOINT;

const onErrorLink = onError((error: any) => {}) as ApolloLink;

const authLink = setContext((_, { headers }) => {
    const token = AuthService.getAccessToken();
    const tokenIsExpired = AuthService.tokenIsExpired();

    if (token && tokenIsExpired) {
        navigate('/logout');
        return headers;
    } else {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        };
    }
}) as ApolloLink;

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT
}) as ApolloLink;

const createApolloClient = () => {
    return new ApolloClient({
        link: ApolloLink.from([authLink, onErrorLink, httpLink]),
        cache: new InMemoryCache({ addTypename: false }),
        queryDeduplication: false
    });
};

export default createApolloClient;
