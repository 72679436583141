import gql from 'graphql-tag';

export const PLAYER_DETAIL_QUERY = gql`
    query PlayerDetailQuery($playerId: ID!) {
        sdk(id: $playerId) {
            id
            name
            sdkType
            version
            defaultConfiguration {
                pageDomains
                sourceDomains
                domainsVerified
                features
            }
            setupType
            customisation
            isActive
            isAlwaysLatest
            isExternal
            isDeleted
            isTestSdk
            isIframeGenerated
            isLicenseOnly
            isUsingLicense
            encodedLicense
            buildMechanism
            cdn {
                url
                zip
            }
            company {
                id
                contract {
                    contractType
                    gracePeriodInDays
                    isTerminated
                    until
                    gracePeriodInDays
                    features
                }
            }
            lastBuildInfo {
                id
                createdAt
                expiresAt
                legacyKey
                status
                cdn
                cdnZipLink
                configuration {
                    id
                    features
                    sourceDomains
                    pageDomains
                    domainsVerified
                }
                version
                creatorEmail
            }
        }
    }
`;
