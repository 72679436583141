import { createTheme } from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';

const theme = createTheme({
    palette: {
        primary: {
            light: '#485C6E',
            main: '#344A5E'
        },
        secondary: {
            main: '#FFC50F',
            '50': '#fff4dc'
        },
        error: {
            light: red[200],
            main: red[300],
            dark: red[500],
            contrastText: 'white'
        },
        warning: {
            main: '#eb8e0c'
        }
    },
    typography: {
        fontFamily: 'Open sans'
    },
    overrides: {
        MuiChip: {
            label: {
                userSelect: 'auto'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '40px'
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: 'white',
                color: 'black',
                fontSize: '14px',
                boxShadow:
                    '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
            }
        }
    }
});

export default theme;
