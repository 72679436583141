import * as React from 'react';

import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from 'react-apollo';
import createApolloClient from './apollo/createApolloClient';
import muiTheme from './styles/createMuiTheme';
import { hotjar } from 'react-hotjar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from 'styled-components';

import Routes from './Routes';

import './styles/fonts.css';
import { env } from './utils/env';

export const apolloClient = createApolloClient();
const stripePromise = loadStripe(env.STRIPE_API_KEY!);
hotjar.initialize(env.HOTJAR_ID, env.HOTJAR_SNIPPET_VERSION);

export const theme = {
    primary: '#344A5E',
    secondary: '#FFC50F',
    warning: '#f79f11',
    error: '#e31717',
    grayedOut: '#d6d6d6',
    gray: '#a3a3a3'
};

export default function AppWrapper() {
    return (
        <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <Elements stripe={stripePromise}>
                            <ApolloProvider client={apolloClient}>
                                <CssBaseline />
                                <Routes />
                            </ApolloProvider>
                        </Elements>
                    </SnackbarProvider>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}
