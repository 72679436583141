import { ServerValidationError } from "./types";

export interface GetValidationErrorOptions {
    // TODO: Rename me to message
    type: string;
}

export interface SingleValidationError {
    message: string;
}

export default function getValidationError(
    error: ServerValidationError,
    options: GetValidationErrorOptions
): SingleValidationError | null {
    if (error.graphQLErrors[0]?.message === options.type) {
        return {
            message: error.graphQLErrors[0].message
        };
    } else {
        return null;
    }
}
