import * as React from "react";

import classNames from "classnames";

import { isUndefined } from "lodash";

import { Icon, makeStyles, Theme, Typography } from "@material-ui/core";

import { SdkType } from "../../__generated__/globalTypes";

const useStyles = makeStyles((theme: Theme) => ({
    flexWrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center"
    },
    content: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center"
    },
    text: {
        textAlign: "center",
        fontSize: "16px"
    },
    externalLabel: {
        "&:after": {
            content: "'external'",
            fontFamily: "Roboto",
            textTransform: "uppercase",
            letterSpacing: "0.12em",
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
            fontSize: "16px",
            color: "#354057",
            position: "absolute",
            bottom: "-26px"
        }
    },
    icon: {
        height: "40px",
        width: "40px",
        marginBottom: "8px"
    },
    light: {
        color: theme.palette.common.white
    },
    dark: {
        color: theme.palette.primary.main
    }
}));

export enum PlayerPlaceholder {
    ADD_PLAYER = "ADD_PLAYER",
    DEMO_PLAYER = "DEMO_PLAYER"
}

export type PlayerCardTypes = SdkType | PlayerPlaceholder;

interface Props {
    text: string;
    type: PlayerCardTypes;
    icon: React.ReactNode;
    isLegacy?: boolean;
}

const PlayerCardLabel = ({ text, type, isLegacy, icon }: Props) => {
    const classes = useStyles();
    const hasDarkColor = type === PlayerPlaceholder.ADD_PLAYER || isLegacy;
    const hasLightColor =
        (!isUndefined(SdkType[type]) ||
            type === PlayerPlaceholder.DEMO_PLAYER) &&
        !isLegacy;

    return (
        <div
            className={classNames(classes.flexWrapper, {
                [classes.dark]: hasDarkColor,
                [classes.light]: hasLightColor
            })}
        >
            <div
                className={classNames(classes.content, {
                    [classes.externalLabel]: isLegacy
                })}
            >
                <Icon className={classes.icon}>{icon}</Icon>
                <Typography
                    variant="h5"
                    className={classNames(classes.text, {
                        [classes.dark]: hasDarkColor,
                        [classes.light]: hasLightColor
                    })}
                >
                    {text}
                </Typography>
            </div>
        </div>
    );
};

export default PlayerCardLabel;
