import {
    Button,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as React from 'react';
import { AuthService } from '../../auth';
import { ContractType } from '../../__generated__/globalTypes';

interface Props {
    onChangePlayerFilter: (input: string) => void;
    onChangeTab: () => void;
    onCreateClick: () => void;
    showPlayersByStatus: string;
    contractType: ContractType;
    canBuild: boolean;
    isAdmin: boolean;
    showLicenseOnly: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        activeTab: {
            background: '#FFF',
            boxShadow:
                '0px 0px 3px -2px rgb(0 0 0 / 20%), 0px 0px 4px 0px rgb(0 0 0 / 14%), 0px 0px 8px 0px rgb(0 0 0 / 12%)',
            borderRadius: '7px 7px 0px 0px',
            zIndex: 1,
            minWidth: '100px',
            height: '100%',
            color: '#344A5E',
        },
        licenseTab: {
            margin: ' 5px 0 0 5px',
            height: '75%',
        },
        customTab: {
            margin: '5px 5px 0 0',
            height: '75%',
        },
        tabs: {
            background: '#EBEBEB',
            borderRadius: '7px 7px 0px 0px',
            minWidth: '100px',
            boxShadow:
                '0px 0px 3px -2px rgb(0 0 0 / 20%), 0px 0px 4px 0px rgb(0 0 0 / 14%), 0px 0px 8px 0px rgb(0 0 0 / 12%)',
        },
        tabContainer: {
            padding: '0 1rem 0 0',
            display: 'flex',
            alignItems: 'flex-end',
        },
        headerContainer: {
            padding: '10px 1rem 0 1rem',
        },
        createAndArchiveContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        [theme.breakpoints.down(509)]: {
            createAndArchiveContainer: {
                alignItem: 'flex-start',
            },
            tabContainer: {
                alignSelf: 'flex-end',
                order: 2,
            },
        },
    }),
);

function PlayersCardHeader({
    onChangePlayerFilter,
    onChangeTab,
    onCreateClick,
    showLicenseOnly,
    showPlayersByStatus,
    contractType,
    canBuild,
    isAdmin,
}: Props) {
    const canWrite = AuthService.canWrite();
    const classes = useStyles();
    return (
        <CardHeader
            className={classes.headerContainer}
            sx={{ typography: { body1: { marginBottom: 0 } } }}
            subheader={
                <Grid container justifyContent="space-between">
                    <Grid item className={classes.tabContainer}>
                        <Tabs value={showLicenseOnly} onChange={onChangeTab}>
                            <Tab
                                className={`${showLicenseOnly ? classes.activeTab : classes.tabs} ${
                                    classes.licenseTab
                                }`}
                                label="Licenses"
                            ></Tab>
                            {contractType !== ContractType.SPORTSBOOK && (
                                <Tab
                                    className={`${!showLicenseOnly ? classes.activeTab : classes.tabs} ${
                                        classes.customTab
                                    }`}
                                    label="Custom"
                                ></Tab>
                            )}
                        </Tabs>
                    </Grid>
                    <Grid item xs={4} className={classes.createAndArchiveContainer}>
                        <Grid container spacing={2} alignItems="center" justifyContent="space-between" wrap="nowrap">
                            <Grid item>
                                <Typography variant="subtitle2">Filter players by status: </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    InputProps={{ style: { marginBottom: 0 } }}
                                    value={showPlayersByStatus}
                                    variant="outlined"
                                    onChange={(e) => onChangePlayerFilter(e.target.value)}
                                >
                                    <MenuItem value={'active'}>Active</MenuItem>
                                    <MenuItem value={'archived'}>Archived</MenuItem>
                                    {isAdmin && <MenuItem value={'deleted'}>Deleted</MenuItem>}
                                </TextField>
                            </Grid>

                            {canWrite && contractType !== ContractType.TRIAL && (
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{ padding: '7px' }}
                                        startIcon={<Add />}
                                        onClick={onCreateClick}
                                        disabled={!canBuild}
                                    >
                                        Create
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
}

export default PlayersCardHeader;
