import React from "react";

import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { useApolloClient } from "react-apollo";
import { AuthService } from "../../auth";
import RegistrationWrapper from "../../components/Template/RegistrationWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down("lg")]: {
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.only("xl")]: {
            marginTop: theme.spacing(12)
        }
    },
    paragraph: {
        color: theme.palette.primary.main,
        fontSize: "16px"
    },
    bold: {
        color: theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: 600
    }
}));

function CompanyBlockedPage(props: RouteComponentProps) {
    const client = useApolloClient();
    AuthService.destroySession();
    client.resetStore();

    const classes = useStyles();

    return (
        <RegistrationWrapper
            children={
                <Grid container style={{ marginTop: "100px" }} spacing={2}>
                    <Grid item>
                        <Typography
                            variant="h6"
                            align="left"
                            className={classes.title}
                        >
                            <span role="img" aria-label="sad-emoji">
                                😩
                            </span>{" "}
                            Oh no! Your company has been blocked!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.paragraph}>
                            Your company doesn't seem to have access to our
                            portal anymore. For more information, contact
                            support-portal@theoplayer.com.
                        </Typography>
                    </Grid>
                </Grid>
            }
        />
    );
}

export default CompanyBlockedPage;
