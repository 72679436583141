import * as React from 'react';

import { Card, CardContent, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { BuildStatus } from '../../__generated__/globalTypes';
import AuthService from '../../auth/AuthService';
import Spinner from '../Spinner';
import { CompanyPlayersQuery, CompanyPlayersQueryVariables } from './__generated__/CompanyPlayersQuery';
import MyPlayersOverview from './MyPlayersOverview';
import { PLAYER_BUILD_PROGRESS_FRAGMENT } from './PlayerCard';
import PlayersCardHeader from './PlayersCardHeader';
import { navigate } from '@reach/router';

export const PLAYER_OVERVIEW_FRAGMENT = gql`
    fragment PlayerOverviewFragment on Sdk {
        id
        sdkType
        name
        isActive
        isTestSdk
        isDeleted
        isExternal
        defaultConfiguration {
            domainsVerified
        }
        version

        buildMechanism
        buildHistory(take: 1) {
            ...PlayerBuildProgressFragment
        }
        licenses {
            expirationDate
        }
    }
    ${PLAYER_BUILD_PROGRESS_FRAGMENT}
`;

export const COMPANY_PLAYERS_QUERY = gql`
    query CompanyPlayersQuery($companyId: ID!, $includeDeleted: Boolean) {
        company(id: $companyId) {
            id
            isReseller
            childCompanies {
                id
                name
            }
            sdks(includeDeleted: $includeDeleted) {
                id
                sdkType
                name
                isTestSdk
                isDeleted
                isExternal
                isActive
                isLicenseOnly
                isUsingLicense
                impressionsMonth
                defaultConfiguration {
                    domainsVerified
                }
                version
                buildMechanism
                buildHistory(take: 1) {
                    id
                    status
                    progress
                    legacyKey
                    expiresAt
                }
                licenses {
                    expirationDate
                }
            }
            contract {
                contractType
                gracePeriodInDays
                isTerminated
                features
                sdkTypes
            }
        }
    }
`;
interface Props {
    companyId: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            overflowX: 'auto',
        },
    }),
);

const isBuildInProgress = (sdk: any) => {
    return sdk.buildHistory.length > 0
        ? sdk.buildHistory[0].status === BuildStatus.IN_PROGRESS || sdk.buildHistory[0].status === BuildStatus.QUEUED
        : false;
};

const isFinished = (sdk: any) => {
    return sdk.buildHistory.length > 0
        ? sdk.buildHistory[0].status === BuildStatus.SUCCESS || sdk.buildHistory[0].status === BuildStatus.FAILED
        : false;
};

const MyPlayers = ({ companyId }: Props) => {
    const classes = useStyles();

    const isAdmin = AuthService.isAdmin();

    const [showPlayersByStatus, setShowPlayersByStatus] = React.useState('active');
    const [showLicenseOnly, setShowLicenseOnly] = React.useState(true);

    const { data, loading, startPolling, stopPolling } = useQuery<CompanyPlayersQuery, CompanyPlayersQueryVariables>(
        COMPANY_PLAYERS_QUERY,
        {
            variables: {
                includeDeleted: isAdmin,
                companyId,
            },
            fetchPolicy: 'network-only',
        },
    );

    const canBuild = AuthService.canWrite() && !data?.company?.contract.isTerminated;

    const finished = data?.company?.sdks.every(isFinished);

    if (finished) {
        stopPolling();
    }

    const inProgress = data?.company?.sdks.some(isBuildInProgress);
    if (inProgress) {
        startPolling(5000);
    }

    if (!data || loading) {
        return <></>;
    }
    return (
        <React.Fragment>
            <Card elevation={3}>
                <PlayersCardHeader
                    onChangePlayerFilter={setShowPlayersByStatus}
                    onCreateClick={() => navigate(showLicenseOnly ? 'players/new' : 'players/new?buildMethod=custom')}
                    onChangeTab={() => setShowLicenseOnly(!showLicenseOnly)}
                    showPlayersByStatus={showPlayersByStatus}
                    contractType={data.company.contract.contractType!}
                    canBuild={canBuild || false}
                    showLicenseOnly={showLicenseOnly}
                    isAdmin={isAdmin}
                />
                <Divider />
                <CardContent className={classes.cardContent}>
                    {loading && (
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            <Spinner />
                        </div>
                    )}
                    {!loading && data && (
                        <MyPlayersOverview
                            company={data.company}
                            playerStatusToShow={showPlayersByStatus}
                            canBuild={canBuild || false}
                            showLicenseOnly={showLicenseOnly}
                        />
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default MyPlayers;
